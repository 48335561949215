@import '../../helpers.scss';

.form-input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  width: 100%;

  .label-title {
    padding-bottom: 0.5rem;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: capitalize;
  }

  .input-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 25px;
      width: 25px;
      color: $primary-text;
      left: 0.75rem;
    }
    input {
      outline: none;
      border: none;
      background: none;
      padding-left: 3rem;
      padding-right: 1rem;
      width: 100%;
      height: 100%;
      font-size: 1rem;
      font-weight: 500;
      background: $secondary-background;
      border-radius: $border-radius;
    }
  }
}
