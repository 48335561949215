.item {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.25rem 0;
  margin: 0;
  button: {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
  }
  .display-flex {
    display: flex;
  }
  .handler {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.1s linear;
    .icon {
      height: 22px;
      width: 22px;
    }
  }
  .handler-active {
    left: 0px;
    opacity: 1;
  }
  .handler-inactive {
    left: -30px;
    opacity: 0;
    pointer-events: none;
  }
  .remove-ingredient-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    outline: none;
    border: none;
    background: none;
    transition: right 0.1s linear, opacity 0.1s linear;
    cursor: pointer;

    .icon {
      height: 22px;
      width: 22px;
    }
  }
  .remove-ingredient-active {
    right: 5px;
    opacity: 1;
  }
  .remove-ingredient-inactive {
    right: -20px;
    opacity: 0;
    pointer-events: none;
  }
  .item-btn {
    display: flex;
    justify-content: flex-start;
    outline: none;
    border: none;
    width: 100%;
    padding: 0;
    margin: 0;
    background: none;
    gap: 0.5rem;
  }
  .hidden {
    position: absolute;
    right: 2000px;
    overflow: 'hidden';
    z-index: -1;
    height: 0px;
  }
  .edit-input {
    position: relative;
    width: 100%;
    height: 40px;
  }

  .label-text-container {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 40px;
    outline: none;
    border: none;
    background: none;
    padding-right: 2rem;
    .text {
      font-size: 1rem;
      font-weight: 700;
      text-align: start;
      transition: all 0.1s linear;
    }
  }
  .margin-active {
    margin-left: 2rem;
  }
}
