@import '../../helpers.scss';

.search-recipes-form {
  position: relative;
  width: 90%;
  max-width: 800px;

  .search-recipes-input-label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 11;

    .icon {
      position: absolute;
      left: 0.75rem;
      height: 24px;
      width: 24px;
    }
    input {
      background: $primary-background;
      outline: none;
      border: none;
      border-radius: $border-radius;
      height: 50px;
      width: 100%;
      font-size: 1.1rem;
      padding: 0.1rem 2.75rem;
      padding-right: 7.25rem;
      color: $primary-text;
      font-weight: 500;
      background: $white;
    }
    .search-recipes-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      color: $white;
      right: 5px;
      font-size: 1.1rem;
      background: $primary;
      height: 80%;
      padding: 0 1.25rem;
      border-radius: $border-radius;
    }
    @media screen and (max-width: 500px) {
      input {
        padding-right: 6.25rem;
      }
      .search-recipes-btn {
        font-size: 1rem;
        padding: 0 1rem;
      }
    }
  }

  .auto-complete-results {
    position: absolute;
    z-index: 10;
    top: 85%;
    display: flex;
    flex-direction: column;
    background: $white;
    padding-top: 0.25rem;
    width: 100%;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .recipes-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
      margin-top: 0.25rem;
      border-top: 1px solid $gray-400;

      .recipe {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        height: 150px;
        background: $white;
        border-bottom: 1px solid $gray-200;
        outline: none;
        border: none;
        cursor: pointer;
        width: 100%;
        transition: 0.1s all linear;

        &:hover {
          background: $primary-background;
          cursor: pointer;
        }

        .img-container {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 125px;
          max-height: 125px;
          width: 100%;
          height: 100%;
          overflow: hidden;
          flex-shrink: 0;
          .img-loading {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
          }
          img {
            z-index: 5;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .info-content {
          display: flex;
          flex-direction: column;
          flex: 1;
          gap: 1.5rem;
          // height: 100px;

          .title {
            font-size: 1.2rem;
            text-transform: capitalize;
            font-weight: 600;
            color: $primary-text;
            text-align: start;
          }
          .data {
            display: flex;
            width: fit-content;
            gap: 1.5rem;
            .item {
              display: flex;
              align-items: center;
              gap: 0.25rem;
              font-weight: 500;
              font-size: 1rem;
              white-space: nowrap;
              color: $primary-text;

              .icon {
                height: 20px;
                width: 20px;
                // stroke-width: 10;
              }
            }
          }
        }
        .tags {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 0.25rem;
          width: 200px;
          .tag {
            padding-right: 0.5rem;
            font-size: 0.7rem;
            font-weight: 700;
          }
          @media screen and (max-width: 725px) {
            display: none;
          }
        }

        @media screen and (max-width: 725px) {
          gap: 0.75rem;
          .img-container {
            max-width: 100px;
            max-height: 100px;
          }
          .info-content {
            .title {
              font-size: 1rem;
            }
            .data {
              gap: 1rem;
              .item {
                display: flex;
                align-items: center;
                gap: 0.25rem;
                font-weight: 500;
                font-size: 0.75rem;

                .icon {
                  height: 16px;
                  width: 16px;
                }
              }
            }
          }
        }
        @media screen and (max-width: 956px) {
          .info-content {
            .title {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
}
