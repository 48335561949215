@import '../../../../helpers.scss';

.recipe-ratings {
  margin-top: 5rem;
  padding-top: 3rem;
  width: 100%;
  border-top: 1px solid $gray-400;
  .title {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;

    @media screen and (max-width: 450px) {
      margin-bottom: 2rem;
    }
  }
  .ratings-reviews-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    .overview {
      display: flex;
      justify-content: center;
      gap: 3rem;

      .average-rating-container,
      .user-rating {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 60px;
      }

      .text {
        font-size: 1rem;
        color: $secondary-text;
        font-weight: 500;
      }
      .average-rating {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .icon {
          height: 30px;
          width: 30px;
        }
        .number {
          font-size: 1.875rem;
          font-weight: 600;
        }
        .count {
          font-size: 1rem;
          font-weight: 500;
          color: $secondary-text;
        }
      }
      .user-rating {
        display: flex;
        flex-direction: column;
        .star-ratings,
        .star-grad,
        .star-container,
        .star,
        svg,
        path {
          &:focus {
            @include outline();
            background: red;
          }
        }
      }
      .user-rate-container {
        height: 30px !important;
        .text {
          font-size: 1.125rem;
          font-weight: 600;
          color: $primary-text;
          &:focus {
            @include outline();
          }
        }
      }

      @media screen and (max-width: 450px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1.5rem;
        .average-rating-container,
        .user-rating {
          gap: 0.25rem;
          width: 170px;
        }
      }
    }

    .reviews {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      max-width: 600px;
      width: 100%;

      .no-reviews {
        margin: 0 auto;
        margin-bottom: 4rem;
        text-align: center;
        font-size: 1.125rem;
        font-weight: 600;
      }

      .curr-user-review {
        margin: 2rem 0;
        border: 1px solid $primary;
        padding: 1rem 1rem 0 1rem;
        border-radius: $border-radius;
        width: 100%;

        .heading {
          text-align: center;
          font-weight: 600;
          color: $primary;
        }
      }

      .review-filters {
        width: 200px;
        padding: 1rem 0 0.5rem 0;
      }

      .leave-review-input-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;
        .recipe-review {
          border: none;
        }

        .error {
          margin: 0;
        }
        .btns-container {
          display: flex;
          justify-content: space-between;
        }
        .close-review-textarea-btn {
          border: none;
          text-decoration: underline;
          background: none;
          cursor: pointer;
          font-size: 0.9rem;
          font-weight: 500;
          color: $secondary-text;
        }
        .leave-review-btn,
        .submit-review-btn {
          font-size: 1rem;
          background: $primary;
          color: $primary-background;
          padding: 0.5rem 1rem;
          border-radius: $border-radius;
          &:focus {
            @include outline();
          }
        }
        .leave-review-btn {
          position: absolute;
          display: none;
          right: 200%;
          opacity: 0;
        }
        .leave-review-btn.visible {
          position: relative;
          display: flex;
          right: 0;
          opacity: 1;
        }
        .review-open {
          position: absolute;
          right: 200%;
          display: none;
          flex-direction: column;
          gap: 1rem;
          opacity: 0;
          width: 100%;
          textarea.review-text-area {
            width: 100%;
            height: 10ch;
            font-size: 1rem;
            padding: 0.5rem;
            box-sizing: border-box;
            resize: none;
          }
          .submit-review-btn {
            margin-left: auto;
          }
          .review-input-title {
            font-size: 1.125rem;
            font-weight: 500;
          }
        }
        .review-open.visible {
          display: flex;
          opacity: 1;
          position: relative;
          right: 0;
        }
      }
      .get-more-reviews {
        display: flex;
        justify-content: center;
        width: 100%;

        .get-more-reviews-btn {
          font-size: 1.125rem;
          padding-top: 2rem;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }
}
