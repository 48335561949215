$primary: #ff5722; // Also located in TrendingRecipes.js
$secondary: #00adb5;

$primary-text: #303841;
$secondary-text: #595f66;
$tertiary-text: #979ba0;

$white: rgb(255, 255, 255);
$gray-50: #fafafa;
$gray-200: #eeeeee;
$gray-300: #e6e6e6;
$gray-400: #d6d6d6;
$gray-500: #bebebe;
$gray-600: #a6a6a6;

$success-green: #28a745;
$error-red: #dc3545;
$error-red-hover: #b02a37;

$alert-error-red: #f8d7da;
$alert-error-red-border: #f5c6cb;
$alert-error-red-text: #721c24;

$alert-success-green: #d4edda;
$alert-success-green-border: #c3e6cb;
$alert-success-green-text: #155724;

$primary-background: #eeeeee;
$secondary-background: #d6d6d6;
$tertiary-background: #b1b1b1;

// Navbar
$nav-height: 6.25rem;

$footer-height: 9.375rem;
$footer-margin: 4rem;
$page-min-height: calc(100vh - #{$footer-height} - #{$nav-height});

$page-top-padding: 2rem;
$page-top-margin: calc(#{$nav-height});

$recipe-page-max-width: 1000px;
$recipe-body-max-width: 800px;

$home-hero-height: calc(500px - #{$page-top-padding} - #{$page-top-margin});

$border-radius: 10px;

$max-width: 1400px;

$card-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

$font-family: 'Montserrat', sans-serif;

@mixin outline() {
  outline-color: #4d90fe;
  // outline-offset: -2px;
  outline-style: auto;
  outline-width: 5px;
}
