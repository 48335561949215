@import '../../helpers.scss';

footer {
  border-top: 1px solid $tertiary-text;
  height: $footer-height;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  color: $primary-text;
  font-size: 0.875rem;
  font-weight: 600;
  box-sizing: border-box;
  .email {
    color: $primary;
  }
  a {
    color: $primary;
  }
  .version {
    font-size: 0.8rem;
    color: $secondary-text;
  }
}
