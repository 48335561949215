@use '../../helpers.scss' as s;

.recipe-thumbnail {
  border: none;
  box-shadow: s.$card-box-shadow;
  border-radius: s.$border-radius;
  padding-bottom: 0.5rem;
  text-decoration: none;
  color: s.$primary-text;
  width: 300px;
  max-width: 300px;
  text-align: center;
  cursor: pointer;
  &:focus {
    @include s.outline();
  }

  .img-container {
    width: 300px;
    overflow: hidden;
    .img {
      border-radius: s.$border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      object-fit: cover;
      width: 100%;
      height: 200px;
    }
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    height: 3ch;
    text-transform: capitalize;
    max-width: 90%;
    font-weight: 500;
    font-size: 1.12rem;
  }
  .price {
    font-size: 0.75rem;
    font-weight: 700;
    color: s.$primary;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
  }
  .info {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    .single-info {
      display: flex;
      align-items: center;
      gap: 0.313rem;
      font-weight: 600;
      font-size: 1rem;
      height: 1.375rem;
      .skeleton {
        height: 1.375rem;
      }
      .icon {
        height: 1.375rem;
        width: 1.375rem;
      }
    }
  }

  @media screen and (max-width: 725px) {
    width: 400px;
    max-width: 400px;

    .img-container {
      width: 400px;
      img {
        height: 250px;
      }
    }

    .title {
      font-weight: 600;
    }
  }
  @media screen and (max-width: 550px) {
    width: 325px;
    max-width: 325px;

    .img-container {
      width: 325px;
      img {
        height: 215px;
      }
    }
  }
  @media screen and (max-width: 375px) {
    max-width: 250px;

    .img-container {
      width: 250px;
      img {
        height: 175px;
      }
    }
  }
}
