@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap');
@import './helpers.scss';

* {
  font-family: $font-family;
  letter-spacing: 0.05em;
  margin: 0;
  padding: 0;
}

#root {
  // background: red;
  overflow: hidden;
}
html {
  overflow: hidden;
}
html,
body {
  height: 100%;
  overflow-x: hidden;
}
body {
  color: $primary-text;
  position: relative;
  width: 100%;
  background: $primary-background;
}

h2 {
  font-weight: 600;
  font-size: 1.5rem;
}
h3 {
  font-weight: 500;
  font-size: 1.5rem;
}
h4 {
  font-weight: 500;
  font-size: 1.125rem;
}
p {
  line-height: 1.75;
}

input {
  &::placeholder {
    color: $tertiary-text;
  }
}
button {
  &:focus {
    @include outline();
  }
}
a {
  &:focus {
    @include outline();
  }
}

.btn {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  color: $primary-text;
}
.action-btn {
  padding: 0.75rem 1.5rem;
  border: 2px solid $primary-text;
  border-radius: $border-radius;
  transition: all 0.1s linear;

  &:hover {
    border: 2px solid $primary;
    background: $primary;
  }
}

.load-more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 45px;
  border: 1px solid $gray-400;
  margin: 0 auto;
  border-radius: $border-radius;
  font-size: 1.3rem;
  white-space: nowrap;

  &:hover {
    border-color: $tertiary-text;
  }

  @media screen and (max-width: 375px) {
    font-size: 1.25rem;
  }
}

.page {
  width: 90%;
  max-width: $max-width;
  min-height: $page-min-height;
  box-sizing: border-box;
  margin: 0 auto;
  padding-top: $page-top-padding;
  margin-top: $page-top-margin;
}
.auth-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 90vh;
  width: 100%;
}

.abs-logo {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: $nav-height;
  display: flex;
  align-items: center;
  position: absolute;
  width: 90%;
  max-width: $max-width;
}

.error,
.success {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: $border-radius;
}
.error {
  background: $alert-error-red;
  color: $alert-error-red-text;
  border: 1px solid $alert-error-red-border;
}
.success {
  background: $alert-success-green;
  color: $alert-success-green-text;
  border: 1px solid $alert-success-green-text;
}

// tags
.tag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($secondary, 0.3);
  border-radius: $border-radius;
  border: 1px solid rgba($secondary, 0.5);
  color: $primary-text;
  padding: 0.25rem 1.5rem 0.25rem 0.5rem;
  font-weight: 600;
  .delete-tag {
    position: absolute;
    right: 0.25rem;
    cursor: pointer;
  }
}

// Alerts
.alert {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: $primary-background;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 0.25rem 2rem;
  width: 90%;
  max-width: 800px;
  border: 1px solid $secondary-background;
  border-radius: $border-radius;

  font-weight: 600;
  font-size: 1rem;

  .content {
    flex: 1;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.25rem 0;
    height: 25px;
    width: 25px;
  }
  .icon.info {
    color: $secondary;
  }
  .icon.success {
    color: $success-green;
  }
  .icon.error {
    color: $error-red;
  }
  .close-icon {
    cursor: pointer;
  }
}

.window-too-small {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  height: 100vh;
  width: 100%;
  background: $primary;
  color: $white;
  p {
    width: 90%;
    max-width: 600px;
    text-align: center;
  }
}

@media print {
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  body,
  html,
  .single-recipe-page {
    height: 100% !important;
    width: 100% !important;
    display: inline-block;
    overflow: visible !important;
  }
  #root {
    height: 100% !important;
    width: 100% !important;
  }
  .single-recipe-page {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding-top: 2rem;
    background-color: white;
    .recipe-container {
      margin: 0 auto;
      padding: 0;
      .header-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
        padding: 0;
        .description-content {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin: 0;
          padding: 0;
          min-height: 0px !important;
          h1.title {
            text-align: center;
            margin: 0 auto;
          }
          .description {
            text-align: center;
            padding: 0 !important;
            margin: 0 !important;
          }
          .recipe-data {
            padding: 0 !important;
            margin: 0 !important;
            max-width: 400px !important;
          }
        }
        .actions {
          display: none !important;
        }
      }
      .recipe-image-container {
        display: none !important;
      }
    }
  }
  .ingredients,
  .directions {
    padding-left: 2rem;
    box-sizing: border-box;
  }
  .directions {
    display: block;
    page-break-before: always;
    .list {
      page-break-before: always;
      .direction {
        page-break-before: always;
        .content {
          page-break-before: always;
        }
      }
    }
  }
  .tags-container {
    display: none;
  }
  .recipe-ratings {
    display: none;
  }
}

// Dnd
.dragging {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
