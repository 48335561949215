@import '../../helpers.scss';

.form-format {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
  .form-container {
    max-width: 350px;
    width: 95%;
    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      .title {
        padding-bottom: 1rem;
      }
      .prompt {
        padding-bottom: 2rem;
        font-weight: 500;
        font-size: 1rem;
        color: $secondary-text;
        .prompt-btn {
          color: $secondary;
          font-weight: 700;
        }
      }
      .input-fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .forgot-password-prompt {
          text-decoration: none;
          font-weight: 500;
          font-size: 0.875rem;
          color: $secondary-text;
          width: fit-content;
        }
      }
    }
  }

  .btn {
    height: 55px;
    width: 100%;
    border-radius: $border-radius;
    font-weight: 600;
  }

  .form-action-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;

    color: $primary-background;
    background: $secondary;
  }
  .available,
  .not-available {
    font-weight: 600;
    font-size: 1rem;
  }
  .available {
    color: $success-green;
  }
  .not-available {
    color: $error-red;
  }
  .google-btn {
    margin-top: 1rem;
    border: 1px solid $secondary-background;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.125rem;
    .icon {
      height: 40px;
      width: 40px;
      color: $secondary;
    }
  }
}
