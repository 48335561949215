@import '../../../../helpers.scss';

.ingredients-container {
  display: flex;
  flex-direction: column;
  .reorder-btn {
    position: absolute;
    z-index: 1000;
    top: 1rem;
    right: 0;
    border: none;
    outline: none;
    font-weight: 700;
    background: none;
    color: $primary;
    cursor: pointer;
  }
  .input-container {
    position: relative;
  }
  .loading-indicator {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
