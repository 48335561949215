@import '../../helpers.scss';

.trending-recipes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  .title {
    text-align: center;
  }
  .recipes {
    display: grid;
    justify-items: center;
    width: fit-content;
    margin: 0 auto;
    margin-top: 2rem;
    gap: 1rem;

    @media screen and (min-width: 725px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: 1100px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
    }
    @media screen and (min-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 2rem;
    }
  }
}
