@import '../../../../helpers.scss';

.ingredient-list {
  display: flex;
  flex-direction: column;
  // min-height: 100px;
  gap: 1rem;
  padding: 1rem 0;
  .ingredients-container {
    .item-btn {
      align-items: center;
    }
    .img-container {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 60px;
      background: $white;
      border-radius: 50%;
      transition: margin 0.1s linear;
      .img {
        height: 50px;
        width: 50px;
        object-fit: contain;
      }
      .no-img {
        height: 35px;
        width: 35px;
      }
    }

    .text-container {
      flex: 1;
      text-align: start;
      font-size: 1rem;
      font-weight: 500;
      padding-right: 2rem;
    }
  }
}
