@import '../../../helpers.scss';

.home-hero {
  display: flex;
  justify-content: center;
  height: $home-hero-height;
  img.background,
  .hero-overlay {
    top: 0;
    // transform: translateY(20%);
    position: absolute;
    width: 100%;
    height: 500px;
    max-height: 500px;
  }

  img.background {
    object-fit: cover;
    min-width: 1600px;
    margin: 0 auto;
  }
  .hero-overlay {
    z-index: 10;
    // background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: rgb(48, 56, 65);
    background: radial-gradient(
      circle,
      rgba(48, 56, 65, 0) 0%,
      rgba(48, 56, 65, 0.8523704481792717) 100%
    );
  }
  .text {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-top: calc(#{$nav-height} / 4);
    margin-bottom: 2.5rem;
    font-size: 1.55rem;
    font-weight: 500;
  }
}
