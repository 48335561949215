.saved-recipes {
  padding-bottom: 2rem;
  .saved-recipes-filters {
    margin-top: 1rem;
    max-width: 250px;
    @media screen and (max-width: 500px) {
      max-width: 100%;
      width: 100%;
    }
  }
  .thumbnails-container {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: fit-content;
    margin: 2rem auto;
    gap: 2rem;

    @media screen and (max-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
    }
    @media screen and (max-width: 1200px) {
      gap: 0.5rem;
    }
    @media screen and (max-width: 1100px) {
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
    @media screen and (max-width: 815px) {
      gap: 1rem;
    }
    @media screen and (max-width: 725px) {
      grid-template-columns: 1fr;
    }
  }
}
