@import '../../../helpers.scss';

.time-input {
  width: 100%;
  .label-title {
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: capitalize;
    color: $secondary-text;
  }

  .time-input-inputs {
    margin-top: 0.5rem;
    display: flex;
    gap: 1rem;
    input {
      padding-right: 0.25rem;
    }
  }
}
