@import '../../helpers.scss';

.release-notes-modal {
  position: relative;
  max-width: 80%;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  max-width: 900px;

  .close-modal {
    top: 1rem;
    right: 1rem;
    position: absolute;
    .icon {
      height: 30px;
      width: 30px;
    }
    &:focus {
      @include outline();
    }
  }
  .release-notes-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .heading {
    // font-size: 1.875rem;
    padding-top: 1rem;
    text-align: center;
  }
  .release-tag {
    font-size: 1.125rem;
    font-weight: 700;
    color: $primary;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
  .release-description {
    max-width: 700px;
    padding-top: 0.5rem;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 3rem 0 2rem 0;
    width: 100%;
    max-width: 700px;
    .section {
      display: flex;
      flex-direction: column;
      .sub-heading {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 1.25rem;
        font-weight: 700;
        // color: $primary;
        text-align: left;

        .icon {
          height: 25px;
          width: 25px;
        }
      }
      .items {
        padding-top: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .item {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-weight: 600;
          color: $secondary-text;

          .icon {
            stroke-width: 0.9;
          }
          .text {
            flex: 1;
          }
        }
      }
    }
  }

  .all-release-notes-btn {
    font-weight: 600;
    font-size: 1rem;
    text-decoration: underline;
    text-align: left;
    margin: 0;
    color: $secondary-text;
    margin-top: 1rem;
  }

  .link {
    color: $secondary;
  }

  @media screen and (max-width: 600px) {
    max-width: 95%;
    width: 95%;
    max-height: 95%;
    height: 95%;
    box-sizing: border-box;
    padding: 1.5rem !important;
  }
}
