@import '../../helpers.scss';

.account-page {
  .account-header {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 2rem;

    .info-container {
      display: flex;
      gap: 2rem;

      .profile-image {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
        height: 100px;
        width: 100px;
      }
      .profile-image.not-set {
        color: $primary;
        font-weight: 500;
        font-size: 3.125rem;
        background: $primary-text;
      }
      .content {
        display: flex;
        flex-direction: column;
        font-size: 1.875rem;
        font-weight: 700;
        justify-content: space-around;
        .username {
          font-size: 1.875rem;
        }
        .edit-profile-btn {
          font-size: 1.125rem;
          border: 1px solid $primary-text;
          border-radius: $border-radius;
          padding: 0.5rem 1rem;
        }
      }
    }
    @media screen and (max-width: 600px) {
      .info-container {
        gap: 1rem;
        .content {
          .username {
            font-size: 1.5rem;
          }
        }
      }
    }
    @media screen and (max-width: 500px) {
      .info-container {
        .profile-image {
          font-size: 2.5rem;
          height: 80px;
          width: 80px;
        }
        .content {
          .username {
            font-size: 1.25rem;
          }
          .edit-profile-btn {
            font-size: 1rem;
            border: 1px solid $primary-text;
            border-radius: $border-radius;
            padding: 0.5rem 0.75rem;
          }
        }
      }
    }
    @media screen and (max-width: 400px) {
      .info-container {
        .profile-image {
          font-size: 2.188rem;
          height: 70px;
          width: 70px;
        }
        .content {
          .username {
            font-size: 1.125rem;
          }
          .edit-profile-btn {
            font-size: 1rem;
            border: 1px solid $primary-text;
            border-radius: $border-radius;
            padding: 0.5rem 0.75rem;
          }
        }
      }
    }
    @media screen and (max-width: 350px) {
      .info-container {
        .content {
          .username {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .account-body {
    margin-top: 2rem;
    .options-bar {
      display: flex;
      gap: 2rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $tertiary-text;

      .selection {
        text-decoration: none;
        font-weight: 700;
        color: $tertiary-text;
      }
      @media screen and (max-width: 450px) {
        justify-content: space-around;
        width: 100%;
        gap: 1rem;
        .selection {
          font-size: 0.875rem;
        }
      }
      @media screen and (max-width: 350px) {
        justify-content: space-between;
        .selection {
          font-size: 0.75rem;
        }
      }
      .selection.active {
        color: $primary-text;
      }
    }
  }
  .no-data-saved {
    text-align: center;
    margin: 0 auto;
    margin-top: 3rem;
    padding-bottom: 3rem;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #666;
    }
  }
}
