@import '../../../../../helpers.scss';

.recipe-review {
  padding: 2rem 0;
  border-top: 1px solid $gray-400;
  // font-weight: 500;
  .head {
    display: flex;
    gap: 0.35rem;
    align-items: flex-start;
    padding-bottom: 1.5rem;

    .name {
      font-weight: 600;
    }
    .date {
      flex: 1;
      text-align: right;
      font-size: 0.875rem;
      font-weight: 500;
      // color: $secondary-text;
    }
    @media screen and (max-width: 450px) {
      position: relative;
      flex-wrap: wrap;
      gap: 0.25rem;
      .name-content {
        display: flex;
        flex-wrap: wrap;
        max-width: 300px;
        column-gap: 0.35rem;
        .name {
          font-size: 0.875rem;
        }
      }
      .rating {
        display: flex;
        .text {
          display: none;
        }
      }
      .date {
        position: absolute;
        color: $tertiary-text;
        right: 0;
        font-size: 0.75rem;
      }
    }
    @media screen and (max-width: 350px) {
      .name-content {
        max-width: 200px;
      }
    }
  }
  .body {
    .text {
      font-size: 1rem;
      font-weight: 500;
      color: $primary-text;
      line-height: 1.5;
    }
    textarea.editing-review {
      width: 100%;
      background: none;
      padding: 0.5rem;
      box-sizing: border-box;
      height: 14ch;
      resize: none;
    }
    .review-options {
      display: flex;
      gap: 1rem;
      padding-top: 1rem;
      .icon {
        height: 18px;
        width: 18px;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        gap: 1.5rem;

        button {
          font-size: 0.875rem;
          font-weight: 600;
          text-decoration: underline;
          color: $tertiary-text;
        }
        button.edit-btn {
          &:hover {
            color: $primary-text;
          }
          &:focus {
            @include outline();
          }
        }
        button.delete-btn {
          &:hover {
            color: $error-red;
          }
          &:focus {
            @include outline();
          }
        }
        button.edit-review {
          position: relative;
          text-decoration: none;
          padding: 0.5rem 1rem;
          background: $primary;
          height: 45px;
          color: $white;
          border-radius: $border-radius;

          display: flex;
          justify-self: center;
          align-items: center;
        }
      }
      button.like-review-btn,
      button.dislike-review-btn {
        &:focus {
          @include outline();
        }
      }
    }
  }
}

.delete-modal {
  .heading {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .text {
    font-weight: 500;
    color: $secondary-text;
    margin-bottom: 2rem;
  }
  .options {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    button {
      font-size: 1.125rem;
      padding: 0.75rem 1.25rem;
      border-radius: $border-radius;
      transition: all 0.3s linear;
    }
    button.cancel {
      background: $gray-300;
      &:hover {
        background: $gray-400;
      }
    }
    button.delete {
      position: relative;
      background: $error-red;
      color: $primary-background;
      &:hover {
        background: $error-red-hover;
      }
    }
  }
}
.btn-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: $border-radius;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}
