@import '../../helpers.scss';

.recipe-form-input,
.recipe-form-textarea,
.recipe-tags-input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;

  .label-title {
    padding-bottom: 0.5rem;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: capitalize;
    color: $primary;
  }

  .input-container {
    display: flex;
    align-items: center;
    width: 100%;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 25px;
      width: 25px;
      color: $primary-text;
      left: 0.75rem;
    }
    .input,
    input,
    textarea {
      background: none;
      border: none;
      outline: none;
      border: 1px solid $tertiary-text;
      border-radius: $border-radius;
      width: 100%;
      height: 100%;
      padding: 0 1rem;
      font-size: 0.9rem;
      font-weight: 500;
    }
    textarea {
      resize: none;
      height: 10ch;
      padding: 1rem;
    }
    .small-textarea {
      height: 5ch !important;
    }

    [contentEditable='true']:empty:not(:focus):before {
      content: attr(data-text);
    }
  }
}
.recipe-form-input {
  .input-container {
    height: 40px;
  }
}
.input-beginning-text {
  position: absolute;
  left: 16px;
  font-size: 0.9rem;
  font-weight: 600;
  color: $secondary-text;
}
.recipe-form-textarea {
  // height: 10ch;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
