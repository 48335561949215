@use '../../helpers.scss' as s;

nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
  height: s.$nav-height;
  // background: s.$primary;
  width: 100%;
  .nav-center {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 90%;
    max-width: s.$max-width;
  }

  .nav-header {
    .hamburger {
      display: none;
    }
    .hamburger.light {
      color: s.$white;
    }

    @media screen and (max-width: 725px) {
      .hamburger {
        position: absolute;
        z-index: 11;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        display: block;
        height: 40px;
        width: 40px;
        color: s.$primary-text;
      }
    }
  }

  .nav-content {
    display: flex;
    justify-content: flex-end;
    .nav-links {
      display: flex;
      gap: 2rem;
      align-items: center;
      justify-content: space-between;
      .nav-link {
        text-transform: capitalize;
        padding: 0.5rem;
        font-size: 1.125rem;
        font-weight: 700;
        color: s.$primary-background;
        text-decoration: none;
        white-space: nowrap;
        &:focus {
          @include s.outline();
        }
      }
      .account-link {
        background: s.$primary-text;
        border-radius: 50%;
        border: 1px solid s.$primary-background;
        width: 45px;
        height: 45px;
        display: flex;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        font-size: 1.875rem;
        color: s.$primary;
        font-weight: 500;
        overflow: hidden;

        .profile-image {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 45px;
          width: 45px;
          object-fit: contain;
        }
      }
      .account-link-loading {
        width: 45px;
        height: 45px;
        border-radius: 50%;
      }
      .dropdown {
        position: relative;
        .dropdown-btn {
          padding: 0.5rem 0;
        }
        &:hover {
          .dropdown-links {
            visibility: 1;
            opacity: 1;
            pointer-events: all;
            z-index: 5;
          }
        }
        @media screen and (max-width: 725px) {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
        }
      }
      .dropdown-links {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        // display: flex;
        flex-direction: column;
        gap: 0.25rem;
        box-sizing: border-box;
        width: 175px;
        padding-bottom: 0.5rem;
        pointer-events: none;

        visibility: 0;
        opacity: 0;
        transition: visibility 0s, opacity 0.1s linear;

        background: s.$white;
        border: 1px solid s.$gray-400;
        border-radius: s.$border-radius;

        .dropdown-section {
          border-bottom: 1px solid s.$gray-400;
        }
        .signed-in-as {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          height: fit-content;

          padding: 0.5rem 1rem;

          .icon {
            height: 20px;
            width: 20px;
            flex-shrink: 0;
            color: s.$primary-text;
          }
          .text {
            font-size: 0.875rem;
            font-weight: 600;
          }
        }
        .nav-link {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          color: s.$primary-text;
          padding: 0.5rem 1rem;
          width: 100%;
          box-sizing: border-box;
          .icon {
            height: 20px;
            width: 20px;
            flex-shrink: 0;
          }
          .text {
            font-size: 0.875rem;
            font-weight: 600;
          }
        }

        @media screen and (max-width: 1400px) {
          right: 0;
          left: unset;
          transform: translateX(0);
        }
        @media screen and (max-width: 725px) {
          position: relative;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          visibility: 1;
          opacity: 1;
          background: none;
          border: none;
          width: fit-content;

          .dropdown-section {
            border: none;
          }
          .signed-in-as {
            display: none;
          }
          .nav-link {
            display: flex;
            justify-content: center;
            width: 100%;

            .icon {
              display: none;
            }
            .text {
              text-transform: capitalize;
              font-size: 1.5rem;
              font-weight: 700;
              color: s.$primary-text;
              text-decoration: none;
              white-space: nowrap;
              padding: 0.5rem 0;
              color: s.$primary-text;
              text-decoration: underline;
              text-align: center;
            }
          }
        }
      }
    }
    @media screen and (max-width: 725px) {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      // display: none;
      height: 100%;
      width: 100%;
      padding-top: 8rem;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.1s linear;
      background: s.$primary-background;

      .nav-links {
        // display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;

        .nav-link {
          padding: 0.5rem 0;
          font-size: 1.5rem;
          color: s.$primary-text;
          text-decoration: underline;
        }
      }
    }
    @media screen and (max-width: 956px) {
      .nav-links {
        gap: 1rem;
      }
    }
  }
  .nav-content.show {
    display: block !important;
    visibility: visible;
    opacity: 1;

    // gap: 5rem;
  }

  .nav-links.dark-nav-links {
    .nav-link {
      color: s.$primary-text !important;
    }
  }
}

.background-gray {
  background: s.$secondary-background;
}
.background-white {
  background: white;
}

.prepify-logo.nav-link {
  z-index: 11;
  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 700;
  color: s.$primary-background;

  .nav-logo {
    font-size: 2.25rem;
    font-weight: 700;
    font-style: italic;
    color: s.$primary;
    text-decoration: none;
    padding: 0.25rem 0.35rem;
    margin: -0.25rem -0.35rem;
    &:focus {
      @include s.outline();
    }
  }

  .beta-tag {
    text-transform: uppercase;
    font-size: 0.875rem;
    background: s.$secondary;
    padding: 0.25rem 0.5rem;
    border: none;
    color: s.$primary-background;
    font-weight: 600;
    border-radius: 5px;
    -webkit-animation: breathing 5s ease-out infinite normal;
    animation: breathing 5s ease-out infinite normal;
    cursor: pointer;
    &:focus {
      @include s.outline();
    }
  }

  @-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(1);
    }

    25% {
      -webkit-transform: scale(1);
      transform: scale(1.1);
    }

    60% {
      -webkit-transform: scale(0.9);
      transform: scale(1);
    }

    100% {
      -webkit-transform: scale(0.9);
      transform: scale(1);
    }
  }
  @keyframes breathing {
    0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    25% {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }

    60% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }

  @media screen and (max-width: 725px) {
    .nav-logo {
      font-size: 1.875rem;
    }
    .beta-tag {
      font-size: 0.75rem;
    }
  }
}
