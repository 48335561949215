@import '../../helpers.scss';

.help-page {
  display: flex;
  justify-content: center;
  min-height: calc(
    100vh - #{$nav-height} - #{$footer-height} - #{$footer-margin}
  );
  .form-container {
    max-width: 400px;
    width: 90%;
    background: $white;
    padding: 2rem;
    height: fit-content;
    border-radius: $border-radius;

    .form-success {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .image-container {
        margin: 1rem 0;
        width: 180px;
        height: 180px;
        img {
          object-fit: contain;
          width: 180px;
          height: 180px;
        }
      }
      button.submit-another-form-btn {
        font-size: 1rem;
        font-weight: 500;
        color: $secondary;
        text-decoration: underline;
      }
    }

    // background: red;
    .report-form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .title {
        text-align: center;
        font-weight: 600;
        font-size: 1.375rem;
      }
      .error {
        margin-bottom: 0;
      }
      .select-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .text {
          width: 5ch;
          font-size: 0.875rem;
          font-weight: 600;
          color: $secondary;
          padding-bottom: 0.25rem;
        }
        .select {
          width: 100%;
        }
      }
      label.title-input-label {
        width: 100%;

        input.title-input {
          width: 100%;
          height: 40px;
          box-sizing: border-box;
          padding: 0.5rem;
        }
      }
      label.description-input-label {
        width: 100%;

        textarea.description-textarea {
          width: 100%;
          box-sizing: border-box;
          resize: vertical;
          padding: 0.5rem;
        }
      }

      label {
        .text {
          font-size: 0.875rem;
          font-weight: 600;
          padding-bottom: 0.25rem;
          color: $secondary;
        }
      }
      input,
      textarea {
        outline: none;
        border: none;
        border: 1px solid $gray-400;
        border-radius: $border-radius;
        font-size: 1rem;
        font-weight: 500;

        &::placeholder {
          color: $gray-500;
        }
        &:focus {
          border-color: $secondary;
        }
      }

      button.submit-help-btn {
        padding: 0.75rem;
        font-size: 1.25rem;
        font-weight: 600;
        border-radius: $border-radius;
        color: $white;
        background: $secondary;
      }
    }
  }
}
