@import '../../../helpers.scss';

.image-picker-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-picker-box {
  position: relative;
  width: 100%;
  border-radius: $border-radius;
  min-height: 200px;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  cursor: pointer;

  input[type='file'] {
    display: none;
  }
  .remove-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    background: rgba(255, 255, 255, 0.829);
    height: 28px;
    width: 28px;
    border-radius: 50%;
    color: black;
    border: 0;
    cursor: pointer;
    &:hover {
      background: white;
    }
    .icon {
      height: 18px;
      width: 18px;
      stroke-width: 25;
    }
  }

  .image-picker-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-picker-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: bold;
    color: #666;
  }
}
.image-picker-box-selected {
  min-height: 350px;
  cursor: default;
}
