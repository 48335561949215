@import '../../helpers.scss';

.forgot-password-page {
  .back-to-login {
    margin-top: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    color: $tertiary-text;
  }
}
