@import '../../helpers.scss';

.single-recipe-page {
  max-width: $recipe-page-max-width;

  h3.title {
    font-weight: 600;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .recipe-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .header-content {
      display: flex;
      width: 100%;
      justify-content: center;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $gray-400;
      gap: 3rem;

      .actions {
        display: flex;
        gap: 1rem;

        .action.skeleton {
          height: 35px;
          min-width: 100px;
        }
        .save-recipe .save-recipe-btn,
        .add-rating .add-rating-btn,
        .print-recipe .print-recipe-btn {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          font-size: 1.125rem;
          border: 1px solid $gray-400;
          background: $gray-300;
          padding: 0.35rem 0.75rem;
          border-radius: $border-radius;
          height: 35px !important;

          &:focus {
            @include outline();
          }

          .loading {
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            position: absolute;
            overflow: hidden;
            background: rgba(255, 255, 255, 0.5);
            .spinner {
              height: 100%;
              width: 100%;
            }
          }

          .disabled-overlay {
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: $border-radius;
            background: rgba(255, 255, 255, 0.5);

            &:hover {
              border: 0px !important;
              cursor: default !important;
            }
          }

          &:hover {
            border: 1px solid $secondary-text;
          }

          .icon {
            height: 20px;
            width: 20px;
          }
        }
      }

      .save-recipe .save-recipe-btn.disabled,
      .add-rating .add-rating-btn.disabled,
      .print-recipe .print-recipe-btn.disabled {
        &:hover {
          border: 1px solid $gray-400 !important;
        }
      }

      .recipe-image-container {
        display: flex;
        // justify-content: flex-start;
        width: 400px;
        height: 400px;
        .img {
          width: 400px;
          height: 400px;
          overflow: hidden;

          object-fit: cover;
        }
        .img.skeleton {
          width: 400px;
          height: 400px;
        }
      }
      .description-content {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        gap: 1rem;
        min-height: 400px;
        width: 100%;
        max-width: 670px;
        // background: red;
        .title {
          text-transform: capitalize;
          padding: 0;
          margin: 0;
        }
        .recipe-price {
          font-size: 0.75rem;
          font-weight: 700;
          color: $primary;
          text-transform: uppercase;
          letter-spacing: 1.2px;
        }
        .description {
          font-size: 1rem;
          font-weight: 500;
          max-width: 600px;
          flex: 1;
          // background: red;
        }
        .recipe-data {
          display: flex;
          flex: 1;
          width: 90%;
          max-width: 500px;
          justify-content: space-between;
          align-items: center;
          // margin-top: 2rem;

          .data-element {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;

            .skeleton {
              min-width: 95px;
              min-height: 85px;
            }
            .icon {
              height: 30px;
              width: 30px;
            }
            h2 {
              font-size: 1rem;
              font-weight: 700;
            }
            .data {
              font-size: 1rem;
              color: $secondary-text;
            }
          }
        }
      }

      @media screen and (max-width: 1100px) {
        gap: 2rem;
        .recipe-image-container {
          width: 350px;
          height: 350px;
          .img {
            width: 350px;
            height: 350px;
          }
          .img.skeleton {
            width: 400px;
            height: 400px;
          }
        }
      }
      @media screen and (max-width: 1000px) {
        .actions {
          gap: 1rem;
          button {
            font-size: 1rem !important;
          }
        }
      }
      @media screen and (max-width: 956px) {
        flex-direction: column;
        align-items: center;
        justify-self: flex-start;

        .mobile-title-content {
          display: flex;
          flex-direction: column;
          align-items: space-between;
          width: 100%;
          max-width: 670px;
          text-align: center;
          .title {
            padding-bottom: 1rem;
            text-transform: capitalize;
          }
          .recipe-price {
            font-size: 0.75rem;
            font-weight: 700;
            color: $primary;
            text-transform: uppercase;
            padding-top: 0.5rem;
            letter-spacing: 1.2px;
          }
          .description {
            max-width: 700px;
            width: 90%;
            margin: 0 auto;
          }
        }
        .description-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;
          min-height: 0px;
          width: 100%;
          max-width: 670px;
        }
        .actions {
          gap: 1rem;
          button {
            font-size: 1.125rem !important;
          }
        }
      }
      @media screen and (max-width: 600px) {
        .description-content {
          width: 90%;
        }
        .actions {
          justify-content: center;
          button {
            font-size: 1rem !important;
          }
          .action.skeleton {
            height: 35px;
            min-width: 80px;
          }
        }
      }
      @media screen and (max-width: 450px) {
        .description-content {
          width: 100%;
        }
        .recipe-image-container {
          width: 300px;
          height: 300px;
          .img {
            width: 300px;
            height: 300px;
          }
          .img.skeleton {
            width: 300px;
            height: 300px;
          }
        }
      }
      @media screen and (max-width: 375px) {
        .description-content {
          width: 100%;
          .recipe-data {
            .data-element {
              h3,
              .data {
                font-size: 0.875rem;
              }
              .skeleton {
                min-width: 82px;
                min-height: 82px;
              }
            }
          }
        }
        .recipe-image-container {
          width: 275px;
          height: 275px;
          .img {
            width: 275px;
            height: 275px;
          }
          .img.skeleton {
            width: 275px;
            height: 275px;
          }
        }
        .actions {
          gap: 0.5rem;
          button {
            font-size: 0.875rem !important;
          }
        }
      }
    }
    .body-content {
      width: 100%;
      max-width: $recipe-body-max-width;

      .tags-container {
        margin: 0 auto;
        margin-top: 4rem;
        max-width: 700px;
        width: 90%;
        .tag-label {
          font-size: 1rem;
          color: $primary;
          font-weight: 700;
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          margin-top: 1.5rem;
          .tag {
            padding-right: 0.5rem;
            cursor: pointer;
            text-decoration: none;

            &:focus {
              @include outline();
            }
          }
          .tag.skeleton {
            height: 24px;
            width: 70px;
            border: none;
          }
        }
        @media screen and (max-width: 500px) {
          .tags {
            .tag {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
    .made-this-recipe {
      margin: 0 auto;
      margin-top: 4rem;
      max-width: 700px;
      width: 90%;
      .content {
        display: flex;
        flex-direction: column;
        width: fit-content;
        align-items: center;
        gap: 0.25rem;
      }
      .made-recipe {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $primary-text;
        border-radius: $border-radius;
        color: $primary-text;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.1s linear;
        width: 105px;
        height: 40px;

        &:hover {
          background: $primary-text;
          color: $primary-background;
        }
        &:focus {
          @include outline();
        }
      }
      span {
        font-size: 0.75rem;
        font-weight: 700;
        color: $secondary-text;
      }
    }
  }
}
