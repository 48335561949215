@import '../../helpers.scss';

.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  min-height: calc(
    100vh - #{$nav-height} - #{$footer-height} - #{$footer-margin}
  );
  padding: 0;
  padding-top: $nav-height;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;

    span.number {
      font-size: 11.25;
      font-weight: 700;
    }
    .image-container {
      height: 190px;
      width: 190px;
      img {
        object-fit: fit;
        min-height: 190px;
        min-width: 190px;
      }
    }

    @media screen and (max-width: 650px) {
      span.number {
        font-size: 10rem;
      }
      .image-container {
        height: 160px;
        width: 160px;
        img {
          min-height: 160px;
          min-width: 160px;
        }
      }
    }
    @media screen and (max-width: 550px) {
      span.number {
        font-size: 8.75rem;
      }
      .image-container {
        height: 140px;
        width: 140px;
        img {
          min-height: 140px;
          min-width: 140px;
        }
      }
    }
    @media screen and (max-width: 450px) {
      span.number {
        font-size: 7.5rem;
      }
      .image-container {
        height: 120px;
        width: 120px;
        img {
          object-fit: fit;
          min-height: 120px;
          min-width: 120px;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      margin-bottom: 1rem;
      text-align: center;
    }
    p.text {
      text-align: center;
      max-width: 700px;
      font-weight: 500;
      color: $secondary-text;
      .contact-link {
        color: $secondary;
      }
    }
    button.home-btn {
      background: $primary;
      color: $white;
      padding: 0.75rem 2rem;
      border-radius: 10px;
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 2rem;
    }
    @media screen and (max-width: 450px) {
      h1 {
        font-size: 1.5rem;
      }
      p.text {
        font-size: 0.875rem;
      }
    }
  }
}
