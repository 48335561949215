@import '../../../../helpers.scss';

.recipe-page.ingredients {
  margin: 0 auto;
  max-width: 700px;
  width: 90%;
  padding-bottom: 1rem;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      .skeleton {
        width: 163px;
        height: 32px;
      }
    }
    .servings {
      display: flex;
      align-items: center;

      .skeleton {
        width: 190px;
        height: 32px;
      }

      .content {
        height: 30px;
        display: flex;
        border: 1px solid $gray-400;
        border-radius: $border-radius;

        input {
          display: flex;
          justify-content: center;
          height: 100%;
          text-align: center;
          font-size: 1.125rem;
          font-weight: 500;
          width: 3ch;
          outline: none;
          border: none;
          label {
            display: flex;
          }
        }

        .counter-btn {
          width: 2rem;
          &:focus {
            @include outline();
          }
        }
      }
      .text {
        font-weight: 600;
        padding-left: 1ch;
      }
    }
    @media screen and (max-width: 540px) {
      align-items: center;
      gap: 0.5rem;

      .servings {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;

        .skeleton {
          width: 100px;
        }
        .content {
          input {
            height: 100%;
          }
        }
        .text {
          position: absolute;
          top: -1.25rem;
          font-size: 0.875rem;
          padding: 0;
          color: $tertiary-text;
          padding-bottom: 0.25rem;
          &::after {
            content: ':';
          }
        }
      }
    }
  }

  .ingredients-lists {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    .list.multi-ingredient-list {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      // border-bottom: 1px solid $gray-400;

      .title {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        padding-bottom: 0.5rem;
        gap: 0.5rem;
        .text {
          color: $primary;
          font-weight: 700;
          font-size: 1rem;
          white-space: nowrap;
        }
        .divider {
          height: 1px;
          width: 100%;
          background: $gray-400;
        }
      }
    }

    .ingredient {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      cursor: pointer;

      margin: 0.25rem 0;
      padding: 0.75rem 0rem;
      padding-right: 1rem;

      font-size: 1rem !important;
      font-weight: 500;
      border-radius: $border-radius;

      &:hover {
        background: $gray-300;
      }

      .ingredient-checkbox {
        -ms-transform: scale(1.4); /* IE */
        -moz-transform: scale(1.4); /* FF */
        -webkit-transform: scale(1.4); /* Safari and Chrome */
        -o-transform: scale(1.4); /* Opera */
        transform: scale(1.4);
        padding: 10px;
        cursor: pointer;
        order: 2; /* Move the checkbox to the end */

        &:checked {
          background: $primary;
        }
        &:focus {
          @include outline();
        }

        @media screen and (max-width: 450px) {
          -ms-transform: scale(1); /* IE */
          -moz-transform: scale(1); /* FF */
          -webkit-transform: scale(1); /* Safari and Chrome */
          -o-transform: scale(1); /* Opera */
          transform: scale(1);
          padding: 10px;
        }
      }
      input[type='checkbox'] {
        accent-color: $primary;
      }

      label {
        display: flex;
        flex: 1;
        pointer-events: none;
        line-height: 1.5;
        .img-container {
          flex-shrink: 0;
          margin-right: 1rem;

          display: flex;
          overflow: hidden;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px;
          background: $white;
          border-radius: 50%;
          transition: margin 0.1s linear;
          .img {
            height: 50px;
            width: 50px;
            object-fit: contain;
          }
          .no-img {
            height: 35px;
            width: 35px;
          }
        }
        .text-container {
          flex: 1;
          text-align: start;
          font-size: 1rem;
          font-weight: 500;
          padding-right: 2rem;
        }
      }
      @media screen and (max-width: 600px) {
        max-width: 100%;
        margin: 0;
        padding: 0.5rem 0;
        font-size: 1rem;
      }

      .ingredient-label {
        font-weight: 700;
        color: $primary-text;
        font-size: 1rem;
        &::after {
          content: ':';
        }
      }
    }
    .ingredient.checked {
      opacity: 0.65;
    }
  }
  @media screen and (max-width: 540px) {
    width: 95%;
    margin: 0 auto;
  }
}
