@import '../../../helpers.scss';

.recipe-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-height: calc(
    100vh - #{$nav-height} - #{$footer-height} - #{$footer-margin}
  );
  padding-top: $nav-height;
  margin: 0 auto;

  h1 {
    margin-top: 2rem;
  }
  p.text {
    text-align: center;
    max-width: 700px;
    font-weight: 500;
    color: $secondary-text;
    padding-bottom: 1rem;
    .contact-link {
      color: $secondary;
    }
  }
}
